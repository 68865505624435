
import { HiOutlineDotsVertical } from "react-icons/hi";
import DocumentMenu from "./document.menu";
import { FcPicture } from "react-icons/fc";
import DocumentPicker from "./picker";
import DocumentViewer from "./document.viewer";
import { useState } from "react";

export default function DocumentImage({ data, isFullPermission, viewOnly }) {

    const [openView, setOpenView] = useState(false)

    const toggleView = () => {setOpenView(true)}

    return <DocumentPicker data={data} toggleView={toggleView}>
        <div onClick={(e) => e.stopPropagation()}>
            <DocumentViewer data={data} open={openView} onCancel={()=>setOpenView(false)} isFullPermission={isFullPermission} />
        </div>
        <div onClick={(e)=>e.stopPropagation()} className="tieu_de flex relative space-x-1 items-center px-2">
            <FcPicture className="text-xl" />
            <span className="txt whitespace-nowrap overflow-hidden text-ellipsis pr-3">{data.fileName}</span>
            <DocumentMenu data={data} isFullPermission={isFullPermission}>
                <HiOutlineDotsVertical className="text-lg cursor-pointer" />
            </DocumentMenu>
        </div>
        <div className="mt-2 h-[180px] rounded-md bg-white flex items-center justify-center cursor-pointer">
            <img src={data.filePath} alt="" className="w-full h-full rounded-md" />
        </div>
    </DocumentPicker>
}