import { APIClient } from "../utils/axiosCustomize"
import { endpoints } from "../utils/constants/endpoints"

const axios = new APIClient()

export const getDepartments = async (query) => {
    try {
        const response = await axios.get(endpoints.Department + "/getDepartments", {
            ...query,
            // sortBy: "CreateDate",
            // sortType: "desc",
        })

        const { data, total, pageSize, pageIndex } = response?.data;

        const dataWithKey = data?.map(i => {
            return { ...i, key: i.Id }
        })

        return { data: dataWithKey, total, pageSize, pageIndex }
    } catch (err) {
        return []
    }
}

export const getDepartmentDetail = async (id) => {
    const { data } = await axios.get(endpoints.Department + `/getById?id=${id}`)
    return data
}

export const insertDepartment = async (body) => {
    const data = await axios.post(endpoints.Department + `/insert`, body)
    return data
}

export const updateDepartment = async (body) => {
    const data = await axios.put(endpoints.Department + `/update`, body)
    return data
}

export const deleteDepartment = async (id) => {
    const data = await axios.delete(endpoints.Department + `/delete?id=${id}`)
    return data
}