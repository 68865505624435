import { Button, Form, Input, Modal, message } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { changeFileName } from "../../../services/document";
import Darkness from "../../../lib/darkness";
import { useParams } from "react-router";

export default function DocumentFileNameEditor(props) {
    const { id } = useParams()
    const { t, i18n } = useTranslation();
    const [form] = Form.useForm()

    useEffect(() => {
        if (props.data) {
            form.setFieldsValue({
                fileName_VN: props.data.fileName_VN,
                fileName_CN: props.data.fileName_CN,
                fileName_TH: props.data.fileName_TH,
            })
        }
    }, [props.data])

    const submitChangeName = async (values) => {

        let prevUpdateObject = props.data
        delete prevUpdateObject.fileName
        delete prevUpdateObject.code
        const { data, success } = await changeFileName({ ...prevUpdateObject, id: props.data.id, languageFiles: [
            { fileName: values.fileName_VN, cultureDataId: 1 },
            { fileName: values.fileName_CN, cultureDataId: 2 },
            { fileName: values.fileName_TH, cultureDataId: 3 },
        ] })
        if (success) {
            props.onCancel()
            Darkness.publish("reload-files", id)
        } else {
            message.error(t("docPage.loi_doi_ten"))
        }
    }

    return <Modal {...props} title={t("docPage.doi_ten")} footer={null}>
        <Form onFinish={submitChangeName} form={form} className="darkness-form" layout="vertical">
            <Form.Item name="fileName_VN" label={t("docPage.viFileName")}>
                <Input />
            </Form.Item>
            <Form.Item name="fileName_CN" label={t("docPage.zhFileName")}>
                <Input />
            </Form.Item>
            <Form.Item name="fileName_TH" label={t("docPage.thFileName")}>
                <Input />
            </Form.Item>
            <div className="flex space-x-2 items-center">
                <Button htmlType="submit">{t("submit")}</Button>
            </div>
        </Form>
    </Modal>
}