import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Spin } from "antd";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import { ReducersName } from "../../utils/constants";
import { localStorageKeys } from "../../utils/constants/local_storage";
import { useAppSelector } from "../../hooks/app";
import { setUserToken } from "../../app/features/users/usersSlice";
import BasicLayout from "../../Layouts/BasicLayout";
import AuthenticationLayout from "../../Layouts/Authentication";
import Login from "../../pages/Auth/Login";
import Profile from "../../pages/Profile";
import User from "../../pages/User";
import Error from "../../pages/Error/index";
import { getDocumentDetail } from "../../services/document";
import ServerError from ".././error/server-error";
import AuthError from ".././error/auth-error";
import NotFound from "../../pages/Error/notfound";
import Sites from "../../pages/Sites";
import InitSignalR from "../../signalr/init";
import DocumentLayout from "../../pages/Documents/Document.Layout";
import DocumentParent from "../../pages/Documents/Document.Parent";
import DocumentContext from "../../pages/Documents/Document.Context";
import FileList from "../../pages/Files/Files.List";
import SharingFile from "../../pages/SharingFile"
import RecycleBin from "../../pages/RecycleBin";
import SharingFolder from "../../pages/SharingFolder";

const ListDepartment = React.lazy(() =>
  import("../../pages/Department/Listdepartment")
);
const DocumentLobby = React.lazy(() =>
  import("../../pages/Documents/Doument.Lobby")
);
const ViewDocument = React.lazy(() =>
  import("../../pages/Documents/ViewDocument")
);
const ListLogs = React.lazy(() => import("../../pages/Logs/ListLog"));
const ListTags = React.lazy(() => import("../../pages/Tags/ListTags"));

const Providers = () => {
  const dispatch = useDispatch();
  const userToken = useAppSelector(`${ReducersName.Users}.userLoginInfo.token`);
  const [firstCheck, setFirstCheck] = useState(false);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <BasicLayout />,
      children: [
        {
          index: true,
          element: <Navigate to={"/documents"} replace />,
        },
        {
          path: "*",
          element: <Navigate to={"/documents"} replace />,
        },
        // {
        //   path: "/home",
        //   element: <Home />,
        // },
        {
          path: "/profile",
          element: <Profile />,
        },
        {
          path: "/employees",
          element: <User />,
        },
        {
          path: "/sites",
          element: <Sites />,
        },
        {
          path: "departments",
          children: [
            {
              index: true,
              element: <ListDepartment />,
            },
          ],
        },
        {
          path: "documents",
          element: <DocumentContext>
            <DocumentLayout />
          </DocumentContext>,
          children: [
            {
              index: true,
              element: <DocumentLobby />,
            },
            {
              path: ":id",
              element: <DocumentParent />,
            },
            {
              path: "view/:id/:slug",
              loader: async ({ params: { id, slug } }) => {
                const data = await getDocumentDetail(id);
                return data;
              },
              element: <ViewDocument />,
            },
          ],
        },
        {
          path: "/files",
          element: <FileList />
        },
        {
          path: "/recycle-bin",
          element: <RecycleBin />
        },
        {
          path: "/logs",
          element: <ListLogs />,
        },
        {
          path: "/tags",
          element: <ListTags />,
        },
      ],
    },
    {
      path: "error",
      element: <Error />,
    },
    {
      path: "/not-found",
      element: <NotFound />,
    },
    {
      path: "/server-error",
      element: <ServerError />,
    },
    {
      path: "/auth-error",
      element: <AuthError />,
    },
    {
      path: "/sharing/:fileId",
      element: <SharingFile />
    },
    {
      path: "/folder/:folderCode",
      element: <SharingFolder />
    }
  ]);

  const authRouter = createBrowserRouter([
    {
      path: "/",
      element: <AuthenticationLayout />,
      children: [
        {
          index: true,
          element: <Navigate to={"/login"} replace />,
        },
        {
          path: "*",
          element: <Navigate to={"/login"} replace />,
        },
        {
          path: "/login",
          element: <Login />,
        },
        {
          path: "/server-error",
          element: <ServerError />,
        },
        {
          path: "/sharing/:fileId",
          element: <SharingFile />
        },
        {
          path: "/folder/:folderCode",
          element: <SharingFolder />
        }
      ],
    }
  ]);

  useEffect(() => {
    const token = localStorage.getItem(localStorageKeys.TOKEN);
    const user = JSON.parse(localStorage.getItem(localStorageKeys?.MY_INFO));

    if (!!token) {
      dispatch(
        setUserToken({
          token,
          user,
        })
      );
    }
    setFirstCheck(true);
  }, [dispatch]);

  if (!firstCheck) return null;

  return (
    <>
      <RouterProvider
        router={userToken ? router : authRouter}
        fallbackElement={<Spin size="large" />}
      />

      <InitSignalR />
    </>
  );
};

export default Providers;
