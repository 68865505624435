export const setUserTokenAction = (state, action) => {
  const { token, user } = action.payload;
  state.userLoginInfo.token = token;
  state.userLoginInfo.user = user;
  state.userLoginInfo.success = true;
};

export const handleOpenModalUserFormAction = (state, action) => {
  const { isOpenModal, isCreate, editData = null } = action.payload;
  state.formAction.isOpenModal = isOpenModal;
  state.formAction.isCreate = isCreate;
  state.formAction.editData = editData;
  state.formAction.titleModal = isCreate
    ? "Tạo mới người dùng"
    : "Chỉnh sửa người dùng";
  state.formAction.buttonOkModal = isCreate ? "Tạo mới" : "Chỉnh sửa";
};
