import { setConnectionSignalR } from "../app/features/signalSlice";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../hooks/app";

const { default: createSignalRConnection } = require("../services/signalR");
const URL_SIGNALR = process.env.REACT_APP_URL_SIGNALR;

const InitSignalR = () => {
  const dispatch = useDispatch();
  const connectionSignalR = useAppSelector(
    `signal.connection`
  );

  const connect = async (hubUrl) => {
    if (!connectionSignalR) {
      const connection = createSignalRConnection(hubUrl);
      try {
        await connection.startConnection();
        dispatch(setConnectionSignalR(connection));
        // console.log(`Connected to ${hubUrl}`);
      } catch (err) {
        console.error(`Error connecting to ${hubUrl}:`, err);
      }
    }
  };

  connect(URL_SIGNALR);
};
export default InitSignalR;
