import { FcClapperboard } from "react-icons/fc";
import { HiOutlineDotsVertical } from "react-icons/hi";
import DocumentMenu from "./document.menu";
import DocumentPicker from "./picker";
import { useState } from "react";
import DocumentViewer from "./document.viewer";
import Thumbnail from "../thumbnail";

export default function DocumentVideo({ data, isFullPermission }) {
    const [openView, setOpenView] = useState(false)

    const toggleView = () => { setOpenView(true) }

    return <DocumentPicker data={data} toggleView={toggleView}>
        <div onClick={(e) => e.stopPropagation()}>
            <DocumentViewer data={data} open={openView} isFullPermission={isFullPermission} onCancel={() => setOpenView(false)} />
        </div>
        <div onClick={(e) => e.stopPropagation()} className="tieu_de flex relative space-x-1 items-center px-2">
            <FcClapperboard className="text-xl" />
            <span className="txt whitespace-nowrap overflow-hidden text-ellipsis pr-3">{data.fileName}</span>
            <DocumentMenu data={data} isFullPermission={isFullPermission}>
                <HiOutlineDotsVertical className="text-lg cursor-pointer" />
            </DocumentMenu>
        </div>
        <div className="mt-2 h-[180px] rounded-md bg-white flex items-center justify-center cursor-pointer">
            <Thumbnail src={data.filePath} className="w-full h-full rounded-md" />
        </div>
    </DocumentPicker>
}