import { APIClient } from "../utils/axiosCustomize";
import { endpoints } from "../utils/constants/endpoints";

const axios = new APIClient();

//LOGIN
export const loginService = async (data) => {
  const res = await axios.post(`${endpoints.Auth}/login`, data);
  return res;
};

export const logoutService = async () => {
  const res = await axios.get(`${endpoints.Auth}/signout`);
  return res;
};

export const refreshTokenService = async () => {
  const res = await axios.get(`${endpoints.Auth}/refresh-token`);
  return res;
};

export const getMeService = async () => {
  const res = await axios.get(`${endpoints.Auth}/getloginuser`);
  return res;
};

export const getUserInforServices = async (id) => {
  const res = await axios.get(`${endpoints.User}/getUserInfor`, { id: id });
  return res;
};

export const updateProfile = async (data) => {
  const res = await axios.put(`${endpoints.User}/update`, data);
  return res;
};

export const getAllUsersService = async (data) => {
  const res = await axios.get(`${endpoints.User}/getUsers`, data);
  return res;
};

export const deleteUserService = async (id) => {
  const res = await axios.delete(`${endpoints.User}/delete`, {
    params: id,
  });
  return res;
};

export const changePassSerices = async (data) => {
  const res = await axios.post(`${endpoints.Auth}/change-password`, data);
  return res;
};

export const createUserService = async (data) => {
  const res = await axios.post(`${endpoints.User}/insert`, data);
  return res;
};

export const updateUserService = async (data) => {
  const res = await axios.put(`${endpoints.User}/update`, data);
  return res;
};

export const getCultureUiInfors = async () => {
  const res = await axios.get(`${endpoints.CultureUiInfor}/getCultureUiInfors`);
  return res;
};
