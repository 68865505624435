import { Dropdown, message } from "antd";
import { useState } from "react";
import { CiCircleInfo, CiEdit, CiSaveDown2, CiShare2, CiTrash } from "react-icons/ci";
import DocumentSharing from "./sharing";
import DocumentNameChanger from "./document.nameChanger";
import { useTranslation } from "react-i18next";
import DocumentRemover from "./remover";
import { deleteFile, deleteTemporaryFile } from "../../services/document";
import DocumentFileNameEditor from "./nameEditor";
import { deleteObject } from "../../lib/s3storage";
import Darkness from "../../lib/darkness";
import DocumentInfo from "./document.info";

export default function DocumentMenu({ data, isFullPermission, children }) {
    const { t, i18n } = useTranslation();
    const [popup, setOpenPopup] = useState({
        nameChanger: false,
        sharing: false,
        info: false,
        remover: false
    })

    const handlePopup = (key, status) => {
        const popupStatus = { "on": true, "off": false }
        setOpenPopup(prev => ({ ...prev, [key]: popupStatus[status] }))
    }

    const submitDelete = async () => {
        const $deleteFile = await deleteTemporaryFile(data.id)

        if ($deleteFile.success) {
            message.success(t("docPage.thong_bao_xoa_thanh_cong"))
            Darkness.publish("reload-files", data.folderId)
        } else {
            message.error(t("docPage.thong_bao_xoa_that_bai"))
        }
        handlePopup("remover", "off")
    }

    const menu = [
        {
            onClick: () => handlePopup("nameChanger", "on"),
            label: <div className="min-w-[200px]">{t("docPage.doi_ten")}</div>,
            icon: <CiEdit />,
            key: '1',
            show: isFullPermission
        },
        {
            onClick: () => handlePopup("sharing", "on"),
            label: <div className="min-w-[200px]">{t("docPage.chia_se")}</div>,
            icon: <CiShare2 />,
            key: '2',
            show: isFullPermission
        },
        {
            onClick: () => handlePopup("info", "on"),
            label: <div className="min-w-[200px]">{t("docPage.thong_tin")}</div>,
            icon: <CiCircleInfo />,
            key: '3',
            show: true
        },
        {
            label: <a href={data.filePath} className="min-w-[200px]">{t("docPage.tai_xuong")}</a>,
            icon: <CiSaveDown2 />,
            key: '4',
            show: true
        },
        {
            onClick: () => handlePopup("remover", "on"),
            label: <div className="min-w-[200px] text-red-500">{t("docPage.xoa")}</div>,
            icon: <CiTrash className="text-red-500" />,
            key: '5',
            show: isFullPermission
        },
    ];

    const visibleMenu = menu.filter(item => item.show);

    return <Dropdown trigger={["click"]} menu={{ items: visibleMenu }}>
        <div className="absolute text-lg right-0">

            <div onClick={(e) => e.stopPropagation()}>
                <DocumentSharing open={popup.sharing} onCancel={() => handlePopup("sharing", "off")} data={data} />
                <DocumentFileNameEditor data={data} open={popup.nameChanger} onCancel={() => handlePopup("nameChanger", "off")} />
                <DocumentRemover open={popup.remover} action={submitDelete} onCancel={() => handlePopup("remover", "off")} />
                <DocumentInfo data={data} open={popup.info} onCancel={() => handlePopup("info", "off")} />
            </div>

            {children}
        </div>
    </Dropdown>
}