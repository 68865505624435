import { Dropdown } from "antd";
import { useTranslation } from "react-i18next";
import { GrCheckmark } from "react-icons/gr"

export default function SharingFilesSorter({ sortBy, sortingBy }) {

    console.log(`re-render: SharingFilesSorter`)

    const { t } = useTranslation()

    const titleMap = {
        "fileName:asc": t("sortFilt.theo_ten"),
        "updateDate:desc": t("sortFilt.gan_day"),
        "fileSize:desc": t("sortFilt.dung_luong")
    }

    const items = [
        {
            onClick: () => sortBy("default"),
            label: <div className="flex items-center space-x-1">
                <span>Mặc định</span>
                {!sortingBy && <GrCheckmark className="text-green-500" />}
            </div>,
            key: '1'
        },
        {
            onClick: () => sortBy("fileName:asc"),
            label: <div className="flex items-center space-x-1">
                <span>Theo tên</span>
                {sortingBy === "fileName:asc" && <GrCheckmark className="text-green-500" />}
            </div>,
            key: '2'
        },
        {
            onClick: () => sortBy("updateDate:desc"),
            label: <div className="flex items-center space-x-1">
                <span>Gần đây</span>
                {sortingBy === "updateDate:desc" && <GrCheckmark className="text-green-500" />}
            </div>,
            key: '3'
        },
        {
            onClick: () => sortBy("fileSize:desc"),
            label: <div className="flex items-center space-x-1">
                <span>Dung lượng</span>
                {sortingBy === "fileSize:desc" && <GrCheckmark className="text-green-500" />}
            </div>,
            key: '4'
        }
    ];

    const menuProps = {
        items
    };

    return <Dropdown.Button menu={menuProps}>
        {sortingBy ? titleMap[sortingBy] : t("sortFilt.sort_label")}
    </Dropdown.Button>
}