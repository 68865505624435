import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Badge, message, Popover, Button, Modal, notification } from "antd";
import { BellOutlined } from "@ant-design/icons";
import { useAppSelector } from "../../hooks/app";
import NotificationItem from "./NotificationItem";
import {
  getNotifications,
  deleteNotification,
  markAsViewed,
} from "../../services/notification";
import { ReducersName } from "../../utils/constants";
import { useTranslation } from "react-i18next";
import { LANGS_MAP } from "../../constants/langs";
import "./styles.css";

const Notify = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const connectionSignalR = useAppSelector(`signal.connection`);
  const user = useAppSelector(`${ReducersName.Users}.userLoginInfo.user`);
  const [countNotification, setCountNotification] = useState(0);
  const [listNotification, setListNotification] = useState([]);
  const [api, contextHolder] = notification.useNotification();
  const lang = LANGS_MAP[localStorage.getItem("language") ?? "vi"];

  const openNotification = (data) => {
    api.info({
      message: t("notification"),
      description: <NotificationItem item={data} noAction={true} lang={lang} />,
      placement: "bottomLeft",
    });
  };

  useEffect(() => {
    const handler = (data) => {
      setListNotification((prev) => [data, ...prev]);
      setCountNotification((prev) => prev + 1);
      openNotification(data);
    };
    connectionSignalR?.connection?.on("FileUpload", handler);

    return () => {
      connectionSignalR?.connection?.off("FileUpload", handler);
    };
  }, [connectionSignalR]);

  useEffect(() => {
    fetchNotifications();
  }, [user]);

  const fetchNotifications = async () => {
    try {
      const params = {
        pageIndex: 1,
        pageSize: 99,
        userId: user?.id,
        isViewAll: true,
      };
      const res = await getNotifications(params);

      setListNotification(res?.data || []);
      setCountNotification(
        res?.data.filter((item, index) => !item.isViewed).length
      );
    } catch (error) {
      console.log(error);
    }
  };

  const reloadNotification = () => {
    fetchNotifications();
  };

  const deleteAllNotification = async () => {
    const params = {
      userId: user?.id,
    };
    try {
      const res = await deleteNotification(params);
      console.log(res);

      if (res.success) {
        message.success(t("delete_notification_success"));
        fetchNotifications();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handClickDeleteAll = () => {
    Modal.confirm({
      content: t("confirm_delete_notification"),
      cancelText: "Cancel",
      onOk: deleteAllNotification,
      cancelButtonProps: {
        type: "primary",
        danger: true,
      },
    });
  };

  const handleMarkViewedAll = async () => {
    const params = {
      userId: user?.id,
    };

    try {
      const res = await markAsViewed(params);

      if (res.success) {
        setCountNotification(0);
        fetchNotifications();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const menu = (
    <div className="min-w-[300px] max-w-[400px]">
      {listNotification.length === 0 ? (
        <div className="text-center font-medium italic text-[#00000081]">
          {t("no_have_notification")}
        </div>
      ) : (
        <>
          <div className="flex justify-between mb-2">
            <Button
              type="text"
              className="text-[#000] font-normal"
              onClick={handClickDeleteAll}
            >
              {t("delete_notification")}
            </Button>
            <Button
              type="text"
              className="text-[#000] font-normal"
              onClick={handleMarkViewedAll}
            >
              {t("mark_viewed")}
            </Button>
          </div>
          <div className="max-h-[400px] overflow-y-auto custom-scroll-notify -mx-3">
            <div className="px-3">
              {listNotification.map((item) => (
                <NotificationItem
                  item={item}
                  key={item?.notifyId}
                  user={user}
                  reload={reloadNotification}
                  lang={lang}
                />
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );

  return (
    <div className="relative flex">
      {contextHolder}
      <Popover placement="bottom" content={menu} trigger="click">
        <Badge size="default" count={countNotification} color="#ff9900">
          <BellOutlined className="text-[1.5rem] text-[#595959] align-middle" />
        </Badge>
      </Popover>
    </div>
  );
};

export default Notify;
