import React, { useState, useEffect } from "react";
import { Modal, Form, Row, Button, Input, message, Checkbox } from "antd";
import { insertSite, updateSite } from "../../services/site";
import { useTranslation } from "react-i18next";

export const SiteFormAction = ({ formState, onClose, reload }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [isSuperSite, setIsSuperSite] = useState(false);
  const { open, title, textButton, editData, isCreate } = formState;

  useEffect(() => {
    if (editData) {
      form.setFieldsValue({
        ...editData,
      });
      setIsSuperSite(editData?.isSuperSite);
    } else {
      form.resetFields();
      setIsSuperSite(false);
    }
  }, [editData]);

  const onFinish = async (values) => {
    setLoading(true);
    const body = {
      ...values,
      isSuperSite
    };

    const { success, message: msg, errors } = isCreate
      ? await insertSite(body)
      : await updateSite(body);

    if (!success) {
      message.error(errors.error.join(" | "));
    } else {
      message.success(msg);
      reload();
    }
    setLoading(false);
  };

  return (
    <Modal title={title} open={open} onCancel={onClose} footer={null}>
      <Form
        form={form}
        layout={"vertical"}
        className="border-t pt-1"
        onFinish={onFinish}
      >
        <Form.Item className="hidden" name="id"></Form.Item>
        <Form.Item
          label={t("site_name")}
          className="my-3"
          name="siteName"
          rules={[{ required: true, message: t("required_site_name") }]}
        >
          <Input placeholder={t("input_site_name")} />
        </Form.Item>
        <Form.Item className="my-3" name="isSuperSite">
          <Checkbox
            checked={isSuperSite}
            onChange={(e) => {
              setIsSuperSite(e.target.checked);
            }}
          >
            {t("verify_belong_super_site")}
          </Checkbox>
        </Form.Item>
        <Row justify="end" className="mt-7 gap-2">
          <Button danger type="primary" onClick={onClose}>
            {t("cancel")}
          </Button>
          <Button type="primary" htmlType="submit" loading={loading}>
            {textButton}
          </Button>
        </Row>
      </Form>
    </Modal>
  );
};
