import { Button, Modal } from "antd";
import { useTranslation } from "react-i18next";

export default function DocumentRemover(props) {

    const { t, i18n } = useTranslation();

    return <Modal {...props} className="!w-[400px]" title={t("docPage.xoa") + "?"} footer={null}>
        <p>{t("docPage.canh_bao_khi_xoa")}</p>
        <div className="mt-4 flex w-full space-x-2 items-center justify-end">
            <Button onClick={props.onCancel} type="primary">{t("cancel")}</Button>
            <Button type="primary" onClick={props.action} danger>{t("docPage.xoa")}</Button>
        </div>
    </Modal>
}