import axios from "axios";

const removeDiacritics = (str) => {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

const sanitizeFileName = (fileName) => {
  return removeDiacritics(fileName).trim().replace(/\s+/g, '-');
};

const appendDateAndRandomStringToFileName = (fileName) => {
  const formattedDate = new Date().toISOString().split('T')[0];
  const randomString = Math.random().toString(36).substring(2, 6);
  const fileExtension = fileName.split('.').pop();
  const baseName = fileName.substring(0, fileName.lastIndexOf('.'));
  const sanitizedBaseName = sanitizeFileName(baseName);
  return `${sanitizedBaseName}-${formattedDate}-${randomString}.${fileExtension}`;
};

export const chunkedUploader = async (file, setProgress, cancelTokenSource) => {
  console.log('start: ', new Date());
  setProgress(0);
  const CHUNK_SIZE = 20 * 1024 * 1024;
  const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
  const MAX_RETRIES = 2;
  const MAX_BATCH = 4;
  const chunksFile = [];

  const fileName = appendDateAndRandomStringToFileName(file.name)

  for (let currentChunk = 0; currentChunk < totalChunks; currentChunk++) {
    const start = currentChunk * CHUNK_SIZE;
    const end = Math.min(start + CHUNK_SIZE, file.size);
    const chunk = file.slice(start, end);
    chunksFile.push(chunk);
  }

  const uploadChunk = (chunk, chunkNumber, totalChunks, fileName, isReup = false, retryCount = 0) => {
    const formData = new FormData();
    formData.append('chunk', chunk);
    formData.append('chunkSize', CHUNK_SIZE);
    formData.append('chunkNumber', chunkNumber);
    formData.append('totalChunks', totalChunks);
    formData.append('fileName', fileName);
    if (isReup) formData.append('isReup', true);

    try {
      return axios.post('/FileUpload/uploadChunk', formData, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "multipart/form-data"
        },
        cancelToken: cancelTokenSource.token
      }).then((res) => {
        if (res && !res.success) {
          if (retryCount < MAX_RETRIES) {
            console.log(`Retrying upload for chunk ${chunkNumber}... (Attempt ${retryCount + 1}/${MAX_RETRIES})`);
            return uploadChunk(chunk, chunkNumber, totalChunks, fileName, true, retryCount + 1);
          } else {
            console.error(`Upload failed for chunk ${chunkNumber} after ${MAX_RETRIES} attempts.`);
          }
        } else {
          const { chunksReceived, totalChunks } = res.data || {};
          if (chunksReceived && totalChunks) {
            setProgress(Math.floor(chunksReceived/totalChunks * 100));
          }
          return res;
        }
      }).catch(err => {
        console.error('Error upload chunk file', err)
      });
    } catch (error) {
      console.log('Error during upload:', error);
    }
  };

  const uploadBatch = async (batch, startChunkNumber) => {
    const promiseBatch = batch.map((chunk, index) =>
      uploadChunk(chunk, startChunkNumber + index, totalChunks, fileName)
    );
    return await Promise.all(promiseBatch);
  };

  const result = [];
  for (let i = 0; i < chunksFile.length; i += MAX_BATCH) {
    const batch = chunksFile.slice(i, i + MAX_BATCH);
    try {
      const res1 = await uploadBatch(batch, i);
      result.push(...res1);
    } catch (error) {
      console.error('Batch upload failed:', error);
      break;
    }
  }

  console.log('end: ', new Date());
  return Promise.all(result);
};

export const checkFullPermissionOnFile = (userInfo) => {
  const isAdmin = userInfo?.isSuperUser;
  const isBaDepartment = userInfo?.department?.isBa;

  return isAdmin || isBaDepartment;
}