import { APIClient } from "../utils/axiosCustomize";
import { endpoints } from "../utils/constants/endpoints";

const axios = new APIClient();

export const getNotifications = async (query) => {
  try {
    const response = await axios.get(endpoints.Notification + "/get-page", {
      ...query,
    });

    const { data, total, pageSize, pageIndex } = response?.data;

    const dataWithKey = data?.map((i) => {
      return { ...i, key: i.notifyId };
    });

    return { data: dataWithKey, total, pageSize, pageIndex };
  } catch (err) {
    return [];
  }
};

export const markAsViewed = async (body) => {
  const data = await axios.put(endpoints.Notification + "/mark-as-viewed", body);
  return data;
};

export const deleteNotification = async (body) => {
  const data = await axios.put(endpoints.Notification + "/delete", body);
  return data;
};
