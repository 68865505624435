import React, { useEffect, useState } from "react";
import { message, Form, Button, Input, Upload, Space, Row, Col, DatePicker, Select } from "antd";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../hooks/app";
import { ReducersName } from "../../utils/constants";
import axios from "axios";
import { update, getUserInfor } from "../../app/features/users/thunks";
import dayjs from "dayjs";
import { getDepartments } from "../../services/department";
import { getSites } from "../../services/site";
import { getUserInforServices } from "../../services/user";

const Profile = () => {
  document.title = "Cập nhật thông tin cá nhân";

  const dispatch = useDispatch();
  const userDetails = useAppSelector(`${ReducersName.Users}.userLoginInfo.user`);
  const [userFullData, setUserFullData] = useState(userDetails);
  const [form] = Form.useForm();
  const [imageUrl, setImageUrl] = useState(userDetails?.avataUrl);
  const [imageUpload, setImageUpload] = useState(null);
  const [datePicker, setDatePicker] = useState(userDetails?.dateOfBirth);
  const [departments, setDepartments] = useState([]);
  const [sites, setSites] = useState([]);
  const isAdmin = userDetails?.isSuperUser;

  useEffect( () => {
    const updateUserFullData = async () => {
      const res = await getUserInforServices(userDetails.id)
      if (res && res.data) {
        setUserFullData(res.data);
      }
    }
    updateUserFullData();
  }, [userDetails.id]);

  useEffect(() => {
    const fetchSites = async () => {
      if (isAdmin) {
        const res = await getSites();
        if (res && res.data) {
          setSites(res.data);
        }
      }
    };
    const fetchDepartments = async () => {
      if (isAdmin) {
        const res = await getDepartments();
        if (res && res.data) {
          setDepartments(res.data);
        }
      }
    };
    fetchSites();
    fetchDepartments();
  }, [isAdmin]);

  console.log(userDetails);
  

  const initialValues = {
    userName: userDetails?.userName,
    fullName: userDetails?.fullName,
    userEmail: userDetails?.userEmail,
    isActive: userDetails?.isActive ? "Kích hoạt" : "Không kích hoạt",
    dateOfBirth: userDetails?.dateOfBirth ? dayjs(userDetails.dateOfBirth) : '',
    phoneNumber: userDetails?.phoneNumber,
    departmentName: userDetails?.department?.departmentName,
    siteName: userDetails?.site?.siteName,
  };
  
  useEffect(() => {
    if (userDetails) {
      form.setFieldsValue(initialValues);
    }
  }, [form, userDetails, userFullData]);

  const getOptionsDepartment = (departments) => {
    return isAdmin ?
        departments.map(({ departmentName }) => (
            { value: departmentName, label: departmentName })) :
        [];
  };

  const getOptionsSite = (sites) => {
    return isAdmin ?
        sites.map(({ siteName }) => (
            { value: siteName, label: siteName })) :
        [];
  };

  const uploadFile = async () => {
    try {
      const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/FileUploadAvatar/uploadfile`,
          { file: imageUpload },
          {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          }
      );
      return response.data?.filePath;
    } catch (error) {
      console.error('Error storing file:', error);
      throw error;
    }
  };

  const getImageUrl = (filePath) => {
    if (filePath) {
      let baseUrl = process.env.REACT_APP_API_URL;
      if (baseUrl.endsWith('/api')) {
        baseUrl = baseUrl.slice(0, -4);
      }

      return `${baseUrl}/${filePath}`;
    }
    return null;
  };

  const onFinish = async (fieldValue) => {
    const avatarUrl = imageUpload ? getImageUrl(await uploadFile()) : userDetails?.avataUrl;
    const isActive = fieldValue.isActive === "Kích hoạt";
    let departmentId, siteId;
    if (isAdmin) {
      const department = departments.find(department => department.departmentName === fieldValue.departmentName);
      const site = sites.find(site => site.siteName === fieldValue.siteName);
      departmentId = department ? department.id : null;
      siteId = site ? site.id : null;
    } else {
      departmentId = userDetails.departmentId;
      siteId = userDetails.siteId;
    }

    const updatedFieldValue = {
      ...fieldValue,
      id: userDetails?.id,
      isSuperUser: userDetails?.isSuperUser,
      isLeader: userDetails?.isLeader,
      siteId: siteId,
      departmentId: departmentId,
      avataUrl: avatarUrl,
      isActive: isActive,
      isBlock: userDetails?.isBlock,
      dateOfBirth: datePicker
    };

    dispatch(update(updatedFieldValue)).then((response) => {
      if (response.payload.success) {
        message.success("Cập nhật thành công");
        dispatch(getUserInfor(userDetails.id));
      } else {
        const errors = response.payload.errors;
        let errorMessage;
        for (const key in errors) {
          errorMessage = errors[key][0];
        }
        message.error(errorMessage);
      }
    });
  };

  const handleDateChange = (date) => {
    const datePicker = new Date(date);
    datePicker.setHours(datePicker.getHours() + 7);
    setDatePicker(datePicker.toISOString());
  }

  const handleChange = (info) => {
    if (info.file.originFileObj) {
      // Kiểm tra xem originFileObj có phải là File hay không
      if (info.file.originFileObj instanceof File) {
        // Get this url from response in real world.
        getBase64(info.file.originFileObj, (imageUrl) => {
          setImageUrl(imageUrl);
          setImageUpload(info.file.originFileObj);
        });
      } else {
        console.error("originFileObj is not a File:", info.file.originFileObj);
      }
    }
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("Bạn chỉ có thể tải lên ảnh!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  return (
    <Form
      form={form}
      name="basic"
      layout="vertical"
      wrapperCol={{
        span: 18
      }}
      onFinish={onFinish}
      autoComplete="off"
      initialValues={initialValues}
    >
      <Row>
        <Col span={8}>
          <Form.Item
            name="avatar"
            label="Ảnh đại diện"
            className="font-bold"
          >
            <Space align="start">
              <Upload
                name="avatar"
                listType="picture-circle"
                className="avatar-uploader"
                showUploadList={false}
                onChange={handleChange}
                beforeUpload={beforeUpload}
              >
                {imageUrl ? (
                  <img
                    src={imageUrl}
                    alt="avatar"
                    style={{
                      width: 200,
                      height2: 200,
                      borderRadius: "50%",
                      objectFit: "contain",
                    }}
                  />
                ) : (
                  <button
                    style={{
                      border: 0,
                      background: "none",
                      width: 200,
                      height2: 200,
                    }}
                    type="button"
                  >
                    <div
                      style={{
                        marginTop: 8,
                      }}
                    >
                      Tải lên
                    </div>
                  </button>
                )}
              </Upload>
            </Space>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <Form.Item
            label="Tên đăng nhập"
            name="userName"
            className="font-bold"
          >
            <Input size="large" disabled />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Tên nhân viên"
            name="fullName"
            className="font-bold"
          >
            <Input size="large" />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <Form.Item
            label="Ngày sinh"
            name="dateOfBirth"
            className="font-bold"
          >
            <DatePicker
              format="DD/MM/YYYY"
              allowClear={false}
              className="w-full"
              size="large"
              onChange={handleDateChange}
              disabledDate={(current) => {
                return current && current.valueOf() > Date.now();
              }}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Email"
            name="userEmail"
            className="font-bold"
            rules={[
              {
                type: "email",
                message: "Vui lòng nhập đúng định dạng email!",
              },
            ]}
          >
            <Input size="large" />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <Form.Item
            label="Số điện thoại"
            name="phoneNumber"
            className="font-bold"
            rules={[
              {
                pattern: /^(\+\d{1,14}|\d{1,14})$/,
                message: "Vui lòng nhập đúng định dạng số điện thoại!"
              }
            ]}
          >
            <Input size="large" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Trang"
            name="siteName"
            className="font-bold"
          >
            <Select disabled size="large" options={getOptionsSite(departments)}></Select>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <Form.Item
            label="Bộ phận"
            name="departmentName"
            className="font-bold"
          >
            <Select disabled size="large" options={getOptionsDepartment(departments)}></Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Trạng thái"
            name="isActive"
            className="font-bold"
          >
            <Input size="large" disabled />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <Form.Item>
            <Button
              style={{
                backgroundColor: "#ffa827",
                width: "100%",
                height: "40px",
                color: "#fff",
                marginTop: "20px",
              }}
              className="custom-button"
              size="large"
              htmlType="submit"
            >
              Chỉnh sửa
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default Profile;
