import { createContext, useContext, useState } from "react";

const Ctx = createContext({
    selectedFiles: [],
    setSelectedFiles: function () {}
})

export default function DocumentContext({ children }) {

    const [selectedFiles, setSelectedFiles] = useState([])

    return <Ctx.Provider value={{ selectedFiles, setSelectedFiles }}>
        { children }
    </Ctx.Provider>
}

export const useDocumentCtx = () => useContext(Ctx)