import { FiDownload, FiEye, FiTrash2, FiX } from "react-icons/fi"
import { useDocumentCtx } from "../../../pages/Documents/Document.Context"
import { useCallback } from "react"

export default function DocumentPickerActions() {

    const { selectedFiles, setSelectedFiles } = useDocumentCtx()

    const download = useCallback(async () => {
        const links = selectedFiles.map(i => i.filePath)

        links.forEach((url, index) => {
            setTimeout(() => {
                const link = document.createElement('a')
                link.href = url
                link.download = ''
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            }, index * 1000)
        })
    }, [selectedFiles])

    return selectedFiles.length > 0 ? <div style={{ border: "solid 1px" }} className="!border-gray-300 absolute bg-gray-100 text-gray-600 items-center flex space-x-3 right-0 -bottom-24 py-2 px-6 rounded-md">
        <FiX className="text-lg cursor-pointer" onClick={() => setSelectedFiles([])} />
        <span>Đã chọn {selectedFiles.length} tệp tài liệu</span>

        <FiDownload onClick={download} className="cursor-pointer text-lg" />
    </div> : <></>
}