import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  defaultActiveTabKey: '1',
};

const documentSlice = createSlice({
  name: 'document',
  initialState,
  reducers: {
    setDefaultActiveKey: (state, action) => {
      state.defaultActiveTabKey = action.payload;
    },
  },
});

export const { setDefaultActiveKey } = documentSlice.actions;

export default documentSlice.reducer;