import { useDocumentCtx } from "../../../pages/Documents/Document.Context";

export default function DocumentPicker({ data, children, toggleView }) {
    const { selectedFiles, setSelectedFiles } = useDocumentCtx()

    const isPicked = selectedFiles.find(i => i.id === data.id)

    const onItemIsClicked = (e) => {
        if (e.ctrlKey) {
            if (isPicked) {
                setSelectedFiles(prev => [...prev.filter(i => i.id !== data.id)])
                return
            }
            setSelectedFiles(prev => [...prev, data])
        } else {
            toggleView()
        }
    }

    return <div onClick={onItemIsClicked} id={`${data?.fileType}-${data?.id}`} className={`${isPicked ? "bg-blue-200" : "bg-gray-100"} rounded-lg px-2 py-3`}>
        {children}
    </div>
}