import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { FiTrash2 } from "react-icons/fi";
import { CiSettings } from "react-icons/ci";
import { Button, Input, Modal, Table, message } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { PlusOutlined } from "@ant-design/icons";
import { SiteFormAction } from "../../components/Site/SiteFormAction";
import { getSites, deleteSite } from "../../services/site";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../hooks/app";
import { ReducersName } from "../../utils/constants";
import DataTable from "../../common/DataTable";
import CountData from "../../common/CountData";
import moment from "moment";
import debounce from "debounce";

const Sites = () => {
  document.title = "Quản lý trang";
  const { t } = useTranslation();
  const nav = useNavigate();
  const [formState, setFormState] = useState({
    title: t("create_new_site"),
    open: false,
    isCreate: false,
    textButton: t("create_new"),
    editData: {},
  });
  const [modal, modalContext] = Modal.useModal();
  const [loading, setLoading] = useState(false);
  const [listSites, setListSites] = useState([]);
  const [params, setParams] = useState({
    textSearch: "",
    pageIndex: 1,
    pageSize: 10,
  });
  const [totalPages, setTotalPages] = useState(0);
  const { isSuperUser } = useAppSelector(
    `${ReducersName.Users}.userLoginInfo.user`
  );

  useEffect(() => {
    if (!isSuperUser) {
      nav("/auth-error");
    }
  }, []);

  useEffect(() => {
    fetchSites();
  }, [params.pageIndex, params.pageSize, params.textSearch]);

  const confirmDelete = async ({ id }) => {
    console.log(id);
    
    const confirmed = await modal.confirm({
      title: t("remove_this_site"),
      content: t("confirm_remove_site"),
      onOk: async () => {
        const { message: msg, success, errors } = await deleteSite(id);
        if (!success) {
          message.error(errors.error.join(" | "));
          return;
        } else {
          message.success(msg);
          await fetchSites();
        }
      },
      cancelButtonProps: {
        danger: true,
        type: "primary",
      },
      onCancel: () => {},
    });
  };

  const fetchSites = async () => {
    setLoading(true);
    const { data, total } = await getSites(params);
    setListSites(data);
    setTotalPages(total);
    setLoading(false);
  };

  const onCLickCreateSite = () => {
    setFormState((prev) => ({
      ...prev,
      title: t("create_new_site"),
      isCreate: true,
      open: true,
      textButton: t("create_new"),
      editData: null,
    }));
  };

  const clickEditSite = (site) => {
    setFormState((prev) => ({
      ...prev,
      title: t("edit_site"),
      isCreate: false,
      open: true,
      textButton: t("update"),
      editData: site,
    }));
  };

  const handleCloseModal = () => {
    setFormState((prev) => ({
      ...prev,
      open: false,
      editData: {},
    }));
  };

  const setTextSearch = (value) => {
    setParams((prev) => ({ ...prev, textSearch: value, pageIndex: 1 }));
  };

  const debouncedSearch = useCallback(debounce(setTextSearch, 300), [
    setTextSearch,
  ]);

  const handleInputChange = useCallback(
    (event) => {
      const text = event.target.value;
      const value = text.trim().replace(/\s\s+/g, " ");
      debouncedSearch(value);
    },
    [debouncedSearch]
  );

  const handleOnchangePage = (pageIndex, pageSize) => {    
    setParams((prev) => ({ ...prev, pageIndex, pageSize }));
  };

  const handleReload = () => {
    setFormState((prev) => ({
      ...prev,
      open: false,
      editData: {},
    }));
    fetchSites();
  };

  const cols = [
    {
      title: t("site_name"),
      dataIndex: "siteName",
      key: "id",
      render: (roleName) => {
        return <span>{roleName}</span>;
      },
    },
    {
      title: t("belong_super_site"),
      dataIndex: "isSuperSite",
      key: "isSuperSite",
      render: (isBa) => {
        return isBa ? t("yes") : t("no");
      },
    },
    {
      title: t("create_date"),
      dataIndex: "createDate",
      key: "createDate",
      render: (col) => {
        return <span>{moment(col).format("DD/MM/YYYY")}</span>;
      },
    },
    {
      title: t("update_date"),
      dataIndex: "updateDate",
      key: "updateDate",
      render: (col) => {
        return <span>{moment(col).format("DD/MM/YYYY")}</span>;
      },
    },
    {
      title: t("action"),
      key: "action",
      render: (_, record) => {
        return (
          <div className="flex items-center space-x-2">
            <Button
              onClick={() => clickEditSite(record)}
              type="primary"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "2px",
              }}
            >
              <CiSettings />
              <span>{t("edit")}</span>
            </Button>

            <Button
              type="primary"
              danger
              onClick={() => confirmDelete(record)}
              className="bg-red-500 hover:bg-red-400 border-none text-white px-2 py-1 rounded-md flex items-center"
            >
              <FiTrash2 />
              <span className="ml-1">{t("delete")}</span>
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <div className="">
      {modalContext}
      <SiteFormAction
        formState={formState}
        onClose={handleCloseModal}
        reload={handleReload}
      />
      <div className="page-heading pb-2">
        <p className="text-lg font-semibold">{t("list_site")}</p>
        <div className="relative flex justify-between items-center mb-2">
          <div className="flex items-center w-full gap-5">
            <Input
              className="max-w-[250px]"
              addonBefore={<SearchOutlined />}
              allowClear
              type="search"
              size="large"
              onChange={handleInputChange}
              placeholder={t("search_site")}
            />
            <CountData total={totalPages} title={t("total_site")} text={t("site")} loading={loading}/>
          </div>
          <div className="flex space-x-2 cursor-pointer">
            <Button
              type="primary"
              onClick={onCLickCreateSite}
              size="large"
              className="w-full text-[.9rem] !border-[#ff9900] text-[#000] hover:!border-[#ff9900] hover:!bg-[#ff9900] hover:!text-[#fff]"
            >
              <PlusOutlined />
              {t("create_site")}
            </Button>
          </div>
        </div>
      </div>

      <DataTable
        listData={listSites}
        columns={cols}
        loadingTable={loading}
        totalPage={totalPages}
        indexPage={params.pageIndex}
        onPageChange={handleOnchangePage}
      />
    </div>
  );
};

export default Sites;
