import { LoadingOutlined } from "@ant-design/icons";

import { Col, Row } from "antd";

const CountData = ({ total, title, text, loading }) => {
  return (
    <Row gutter={[16, 16]}>
      <Col className="!pl-0 flex items-center">
        <div className="text-sm my-1 truncate flex items-center">
          <div className="text-primary text-sm">{title}:</div>
          <div className="border-[1px] border-solid border-primary p-[2px] px-2 rounded-md ml-2 text-[0.8rem] text-primary bg-[#ff990023] lowercase">
            {loading ? <LoadingOutlined style={{ fontSize: 10 }} /> : (total || 0)} {text}
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default CountData;