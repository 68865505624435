export const endpoints = {
  User: "/User",
  Auth: "/Auth",
  Document: "/Content",
  Site: "/Site",
  Department: "/Department",
  Tag: "Tag",
  FileUpload: "FileUpload",
  LogAction: "LogAction",
  Notification: "Notification",
  CultureUiInfor: "CultureUiInfor",
};
