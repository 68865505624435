import { Button, Form, Input, Modal } from "antd";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { changeFolderName, changeRootFolderName } from "../../services/document";
import { useParams } from "react-router";
import Darkness from "../../lib/darkness";

export default function DocumentNameChanger(props) {
    const { id } = useParams()
    const { t, i18n } = useTranslation();
    const [form] = Form.useForm()

    useEffect(() => {
        if (props.data) {
            // form.setFieldsValue({
            //     folderName_VN: props.data.folderName_VN,
            //     folderName_CN: props.data.folderName_CN,
            //     folderName_TH: props.data.folderName_TH,
            // })
        }
    }, [props.data])

    const submitChangeName = useCallback(async (values) => {
        if (id) {
            const { data, success } = await changeFolderName({
                ...props.data, languageFolders: [
                    { cultureDataId: 1, folderName: values.folderName_VN },
                    { cultureDataId: 2, folderName: values.folderName_CN },
                    { cultureDataId: 3, folderName: values.folderName_TH }
                ]
            })
            if (success) {
                props.onCancel()
                Darkness.publish("reload-folders", id)
            }
        } else {
            const { data, success } = await changeRootFolderName({
                ...props.data, languageFolders: [
                    { languageCode: "vn", folderName: values.folderName_VN },
                    { languageCode: "cn", folderName: values.folderName_CN },
                    { languageCode: "th", folderName: values.folderName_TH }
                ]
            })
            if (success) {
                props.onCancel()
            }
        }
    }, [id, props.data])

    return <Modal {...props} title={t("docPage.doi_ten")} footer={null}>
        <Form onFinish={submitChangeName} form={form} className="darkness-form" layout="vertical">
            <Form.Item name="folderName_VN" label={t("docPage.viFileName")}>
                <Input />
            </Form.Item>
            <Form.Item name="folderName_CN" label={t("docPage.zhFileName")}>
                <Input />
            </Form.Item>
            <Form.Item name="folderName_TH" label={t("docPage.thFileName")}>
                <Input />
            </Form.Item>
            <div className="flex space-x-2 items-center">
                <Button htmlType="submit">{t("submit")}</Button>
            </div>
        </Form>
    </Modal>
}