import { Fragment, useEffect, useRef, useState } from "react"
import { getFolderPublicDetail, getPublicFiles, getPublicPaths } from "../services/document"
import { useNavigate, useParams } from "react-router-dom"
import { FcFolder } from "react-icons/fc";
import { Button, message, Modal, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { IoCloudDownloadOutline, IoReload } from "react-icons/io5";
import SharingFilesBox from "../components/sharing/sharing.filesBox";
import InfiniteScroll from "react-infinite-scroll-component";
import DocumentImage from "../components/document/document.image";
import SharingFilesFilter from "../components/sharing/sharing.filesFilter";
import SharingFilesSorter from "../components/sharing/sharing.filesSorter";
import DocumentVideo from "../components/document/document.video";

export default function SharingFolder() {
    const { folderCode } = useParams()
    const { t } = useTranslation()
    const nav = useNavigate()
    const [openConfirm, setOpenConfirm] = useState(false)
    const [isInit, setIsInit] = useState(true)
    const [filteringBy, setFilteringBy] = useState(undefined)
    const [sortingBy, setSortingBy] = useState(undefined)
    const [loading, setIsLoading] = useState(false)
    const [thisFolder, setThisFolder] = useState(undefined)
    const [files, setFiles] = useState([])
    const [index, setIndex] = useState(2);
    const [hasMore, setHasMore] = useState(true);

    const load = async (filterBy = undefined, sortBy = undefined) => {

        setIsLoading(true)
        const { data } = await getPublicFiles({
            folderCode, pageSize: 20,
            ...(!!filterBy && { fileType: filterBy }),
            ...(!!sortBy && { sortBy })
        })
        setHasMore(data.isNextPage);
        setFiles(data.data)

        setIndex(2);

        setIsLoading(false)
    }

    useEffect(() => {

        getFolderPublicDetail(folderCode).then(res => {
            if (res.success) {
                setThisFolder(res.data)
            }
        })

        load()

        return () => {
            setIsInit(true)
        }
    }, [folderCode])

    const onFolderClicked = (code) => {
        setFilteringBy(undefined)
        setSortingBy(undefined)
        nav(`/folder/${code}`)
    }

    const downloadAll = async () => {
        const publicPaths = await getPublicPaths({ folderCode })

        if (publicPaths.data) {
            publicPaths.data.forEach((url, index) => {
                setTimeout(() => {
                    const link = document.createElement('a')
                    link.href = url
                    link.download = ''
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)
                }, index * 1000)
            })
        }

        setOpenConfirm(false)
    }

    const fetchMore = async () => {
        setIsLoading(true)
        try {
            const getFile = await getPublicFiles({
                folderCode,
                pageSize: 20,
                pageIndex: index,
                ...(!!filteringBy && { fileType: filteringBy }),
                ...(!!sortingBy && { sortBy: sortingBy }),
            });

            // Nếu không còn dữ liệu, thoát sớm
            if (!getFile.data || !getFile.data.data.length) {
                return;
            }

            setFiles((prevItems) => [...prevItems, ...getFile.data.data]);
            setHasMore(getFile.data.isNextPage);
            setIndex(index + 1);
        } catch (error) {
            console.error("Lỗi khi tải thêm dữ liệu:", error);
            message.error("Có lỗi xảy ra khi tải thêm dữ liệu.");
        }
        setIsLoading(false);
    };

    const Folder = (props) => {
        return <div style={{ border: "1px solid" }} onClick={() => onFolderClicked(props.code)} className="rounded-md space-x-2 bg-gray-100 hover:bg-gray-200 !border-gray-100 px-4 py-2 flex items-center cursor-pointer">
            <FcFolder className="text-lg" />
            <span className="text-sm font-medium">{props.folderName}</span>
        </div>
    }

    const filterByType = async (filterType) => {

        if (filterType === "all") {
            setFilteringBy(undefined)
            await load(undefined, sortingBy)
            return
        }

        setFilteringBy(filterType)
        await load(filterType, sortingBy)
    }

    const sortBy = async (by) => {
        if (by === "default") {
            setSortingBy(undefined)
            await load(filteringBy, undefined)
            return
        }

        setSortingBy(by)
        await load(filteringBy, by)
    }

    const refresh = async () => {
        await load(filteringBy, sortingBy)
    }

    return <div className="relative px-6 py-4 flex items-center justify-center">
        {loading && (
            <div className="fixed top-[40%] left-[50%]">
                <Spin />
            </div>
        )}

        <Modal onOk={downloadAll} open={openConfirm} onCancel={()=>setOpenConfirm(false)} title={t("sharePage.download_all_file")}>
            <p>{t("sharePage.download_all_warning", { count: thisFolder?.countFile || 0 })}</p>
        </Modal>

        <div style={{ border: "1px solid" }} className="bg-gray-100 rounded-md px-4 py-3 !border-gray-200">

            <div className="!sticky top-0 bg-gray-100 z-10 px-4 flex items-center justify-between">
                <p>{thisFolder?.folderName}</p>
                <div className="flex space-x-2 items-center">
                    <Button onClick={refresh} icon={<IoReload />} className="flex items-center">{t("refresh")}</Button>
                    <Button onClick={()=>setOpenConfirm(true)} icon={<IoCloudDownloadOutline />} className="flex items-center">{t("docPage.tai_cac_file")}</Button>
                    <SharingFilesSorter sortBy={sortBy} sortingBy={sortingBy} />
                    <SharingFilesFilter filterByType={filterByType} filteringBy={filteringBy} />
                </div>
            </div>

            <div style={{ border: "1px solid" }} className="w-[90vw] overflow-y-auto bg-white rounded-md px-4 py-2 !border-gray-200">

                <div className=" grid gap-4 grid-cols-5 pb-4 pt-2">

                    {thisFolder && thisFolder.folders.map(folder => <Folder key={folder.id} {...folder} />)}

                </div>

                <SharingFilesBox>

                    <InfiniteScroll
                        dataLength={files.length}
                        next={fetchMore}
                        hasMore={hasMore}
                    >
                        <div className="grid grid-cols-5 gap-4">
                            {files.map((doc) => (
                                <Fragment key={doc.id}>
                                    {doc.fileType === "image" ? <DocumentImage data={doc} isFullPermission={false} viewOnly={false} />
                                        : <DocumentVideo data={doc} isFullPermission={false} viewOnly={false} />}
                                </Fragment>
                            ))}
                        </div>
                    </InfiniteScroll>

                </SharingFilesBox>
            </div>

        </div>
    </div>
}