export const userInitialState = {
  userLoginInfo: {
    token: null,
    user: {},
    loading: false,
    error: false,
    success: false,
  },
  allUsers: {
    data: [],
    loading: false,
    error: false,
    success: false,
  },
  formAction: {
    isOpenModal: false,
    isCreate: false,
    editData: null,
    titleModal: "Tạo mới người dùng",
    buttonOkModal: "Tạo mới",
  },
};
