import { Button, List, Dropdown, Tabs, message } from "antd";
import { useTranslation } from "react-i18next";
import { Modal } from "antd";
import { useEffect, useState } from "react";
import { GoFile } from "react-icons/go";
import { FiTrash2, FiX } from "react-icons/fi";
import { LuTimerReset } from "react-icons/lu";
import { emptyTrash, getTrash } from "../services/document";
import TrashFolderTab from "../components/trash/trash.folders";
import TrashFileTab from "../components/trash/trash.files";
import Darkness from "../lib/darkness";
import Destroyer from "../components/destroyer";

export default function RecycleBin() {
  document.title = "Thùng rác";

  const { t } = useTranslation();
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [folders, setFolders] = useState([]);
  const [openDestroyer, setOpenDestroyer] = useState()
  const [files, setFiles] = useState([]);

  const load = async () => {
    const [{ data: getTrashFiles }, { data: getTrashFolders }] =
      await Promise.all([getTrash({ typeFile: 1 }), getTrash({ typeFile: 2 })]);

    setFolders(getTrashFolders);
    setFiles(getTrashFiles);
  };

  const loadFolders = async (q) => {
    const { data } = await getTrash({ typeFile: 2, ...q });
    setFolders(data);
  };

  const loadFiles = async (q) => {
    const { data } = await getTrash({ typeFile: 1, ...q });
    setFiles(data);
  };

  const empty = async () => {
    const res = await emptyTrash();
    message.success(res.message)
    await load()
    setOpenDestroyer(false)
  };

  useEffect(() => {
    load();

    Darkness.subscribe("load_folders", async (args) => {
      console.log(args.page);
      await loadFolders({ pageIndex: args.page });
    });

    Darkness.subscribe("load_files", async (args) => {
      console.log(args.page);
      await loadFiles({ pageIndex: args.page });
    });
  }, []);

  const tabs = [
    {
      key: "1",
      label: t("folder"),
      children: <TrashFolderTab data={folders} />,
    },
    {
      key: "2",
      label: t("file"),
      children: <TrashFileTab data={files} />,
    },
  ];

  const TabExtend = () => (
    <div>
      <Button onClick={()=>setOpenDestroyer(true)}>{t("trang_thung_rac.xoa_tat_ca")}</Button>
    </div>
  );

  return (
    <div className="">

      <Destroyer
      open={openDestroyer}
      onCancel={()=>setOpenDestroyer(false)}
      title={t("trang_thung_rac.xoa_tat_ca")}
      warning={t("trang_thung_rac.canh_bao_xoa_vinh_vien")}
      action={empty}/>

      <Modal
        centered
        open={openDeleteConfirm}
        footer={null}
        onCancel={() => setOpenDeleteConfirm(false)}
      >
        <div>
          <p className="text-xl font-medium">
            {t("trang_thung_rac.xoa_vinh_vien")}
          </p>
          <p>{t("trang_thung_rac.canh_bao_xoa_vinh_vien")}</p>
        </div>
        <div className="flex items-center justify-end space-x-2">
          <Button onClick={() => setOpenDeleteConfirm(false)}>
            {t("cancel")}
          </Button>
          <Button type="primary" danger>
            {t("trang_thung_rac.xoa_vinh_vien")}
          </Button>
        </div>
      </Modal>

      <p className="text-2xl font-medium ">{t("thung_rac")}</p>

      <Tabs
        defaultActiveKey="1"
        items={tabs}
        tabBarExtraContent={<TabExtend />}
      />
    </div>
  );
}
