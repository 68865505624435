import React, { useEffect, useState } from "react";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
  RollbackOutlined,
  LockOutlined,
} from "@ant-design/icons";
import {
  Layout,
  Button,
  Avatar,
  Dropdown,
  Modal,
  Form,
  Input,
  message,
  Select,
  Flex,
} from "antd";
import { useNavigate } from "react-router-dom/dist";
import { routes } from "../../utils/constants/routes";
import { useDispatch } from "react-redux";
import { changePass } from "../../app/features/users/thunks";
import { useAppSelector } from "../../hooks/app";
import { ReducersName } from "../../utils/constants";
import { logout } from "../../app/features/users/usersSlice";
import Notify from "../../components/notify/notify";
import { useTranslation } from "react-i18next";
import { getCultureUiInfors } from "../../services/user";
import th from "../../assets/images/thailand.svg";
import vi from "../../assets/images/vietnam.svg";
import zh from "../../assets/images/china.svg";

const flags = {
  vi: vi,
  th: th,
  zh: zh,
};

const { Header } = Layout;
const { Option } = Select;

const HeaderMain = ({ collapsed, setCollapsed }) => {
  const dispatch = useDispatch();
  const userDetails = useAppSelector(
    `${ReducersName.Users}.userLoginInfo.user`
  );
  const { t, i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [listLanguage, setListLanguage] = useState([]);

  useEffect(() => {
    const getCultureUi = async () => {
      const res = await getCultureUiInfors();
      if (res.success) {
        setListLanguage(res.data.data);
      }
    };

    getCultureUi();
  }, []);
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("language", lng);
    setCurrentLanguage(lng);
    window.location.reload();
  };

  const changePassword = (data) => {
    data.userName = userDetails.userName;
    dispatch(changePass(data)).then((action) => {
      if (!action.payload?.errorCode) {
        setVisible(false);
        form.resetFields();
        message.success(t("change_pass_success"));
      } else {
        message.error(t("change_pass_fail"));
      }
    });
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const items = [
    {
      label: t("user_info"),
      key: "1",
      icon: <UserOutlined />,
      onClick: () => navigate(routes.profile),
    },
    {
      label: t("change_pass"),
      key: "2",
      icon: <LockOutlined />,
      onClick: () => setVisible(true),
    },
    {
      label: t("logout"),
      key: "3",
      icon: <RollbackOutlined />,
      onClick: handleLogout,
    },
  ];

  return (
    <>
      <Header
        style={{
          padding: 0,
          background: "#fff",
        }}
      >
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <Button
              type="text"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => setCollapsed(!collapsed)}
              style={{
                fontSize: "16px",
                width: 64,
                height: 64,
              }}
            />
          </div>
          <div className="flex justify-end items-center mr-10 gap-11 cursor-pointer">
            <Notify />

            <Flex align="center" gap="small">
              <Select
                style={{ width: "140px" }}
                value={currentLanguage}
                onChange={changeLanguage}
              >
                {listLanguage.map((value) => (
                  <Option
                    key={value.cultureCodeI18}
                    value={value.cultureCodeI18}
                  >
                    <div className="flex items-center gap-2">
                      <img
                        src={flags[value.cultureCodeI18]}
                        alt="flags"
                        className="w-5 rounded-full"
                      />
                      {t(`languageOptions.${value.cultureCodeI18}`)}
                    </div>
                  </Option>
                ))}
              </Select>

              <Dropdown
                menu={{
                  items,
                }}
                trigger={["click"]}
              >
                <div className=" bg-white border-2 border-solid border-primary h-[55px] flex px-[15px] gap-[15px] rounded-xl items-center justify-center">
                  <div className="text-white h-full">
                    <div className="h-1/2 flex items-center justify-center text-black font-semibold text-[17px]">
                      {userDetails?.fullName}
                    </div>
                    <div className="h-[40%] flex items-center justify-center text-black font-semibold text-[15px]">
                      {userDetails?.isSuperUser
                        ? t("admin")
                        : userDetails?.isLeader
                        ? t("leader")
                        : t("user")}
                    </div>
                  </div>
                  <Avatar
                    src={userDetails?.avataUrl}
                    size="large"
                    icon={<UserOutlined />}
                  />
                </div>
              </Dropdown>
            </Flex>
          </div>
        </div>
      </Header>
      <div>
        <Modal
          title={t("change_pass")}
          open={visible}
          footer={false}
          onCancel={handleCancel}
        >
          <Form layout="vertical" onFinish={changePassword}>
            <Form.Item
              label={t("old_pass")}
              name="oldPassword"
              rules={[{ required: true, message: t("required_old_pass") }]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              label={t("new_pass")}
              name="newPassword"
              dependencies={["oldPassword"]}
              rules={[
                { required: true, message: t("required_new_pass") },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("oldPassword") !== value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(t("validate_same_new_pass"))
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              label={t("confirm_new_pass")}
              name="confirmPassword"
              dependencies={["newPassword"]}
              rules={[
                { required: true, message: t("required_confirm_new_pass") },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("newPassword") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(t("validate_not_match_confirm_new_pass"))
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item className="text-right">
              <Button
                style={{
                  backgroundColor: "#ffa827",
                  height: "40px",
                  color: "#fff",
                }}
                className="custom-button"
                size="large"
                htmlType="submit"
              >
                {t("confirm")}
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </>
  );
};

export default HeaderMain;
