export const VIDEO_MIME_SUPPORT = [
    "video/mp4",
    "video/x-msvideo",
    "video/x-ms-wmv",
    "video/quicktime",
    "video/x-flv",
    "video/webm",
    "video/mpeg",
    "video/3gpp"
]

export const IMAGE_MIME_SUPPORT = [
    "image/jpeg",
    "image/png",
    "image/gif",
    "image/bmp",
    "image/tiff",
    "image/webp"
]